import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import { type Theme, createTheme } from '@mui/material/styles';

const White = '#ffffff';

const Gray75 = '#ABBBD3';
const BlueLight = '#05b4e0';
const GreenLight = '#68c151';
const RedLight = '#ef5350';
const DarkBlue = '#183253';
declare module '@mui/material/styles' {
	interface PaletteColor {
		percent35?: string;
		percent50?: string;
	}
	interface SimplePaletteColorOptions {
		percent35?: string;
		percent50?: string;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		blue: true;
		ButtonFlightSchedule: true;
		AcceptDialog: true;
		OtherActionDialog: true;
		Create: true;
		Edit: true;
		Activate: true;
		Deactivate: true;
		Delete: true;
		View: true;
		Back: true;
		Confirm: true;
		Cancel: true;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		ExplicationTextBold: true;
	}
}

const extendTheme = (theme: Theme) => {
	theme.typography.hero = {
		fontFamily: 'Lato',
		fontWeight: 300,
		fontSize: '36px',
		lineHeight: '43.2px',
	};

	theme.typography.hero2 = {
		fontFamily: 'Lato',
		fontWeight: 300,
		fontSize: '24px',
		lineHeight: '30px',
	};

	theme.typography.hero3 = {
		fontFamily: 'Lato',
		fontWeight: 300,
		fontSize: '16px',
		lineHeight: '20px',
	};

	theme.typography.h1 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '2rem',
	};
	theme.typography.h2 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '20px',
	};
	theme.typography.h3 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '18px',
	};
	theme.typography.h4 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '19px',
	};
	theme.typography.h5 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '21.6px',
	};
	theme.typography.h6 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '19px',
	};

	theme.typography.body1 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '19px',
	};
	theme.typography.smallText = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '14px',
	};
	theme.typography.body3 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '10px',
		lineHeight: '12px',
	};

	return theme;
};
const primaryPalette = {
	main: '#0070b3',
	light: '#0070b380',
	dark: '#0080b4',
	contrastText: '#ffffff',
};

const secondaryPalette = {
	main: '#19255b',
	light: '#19255b80',
	dark: '#19258b',
	contrastText: '#ffffff',
};
const whitePalette = {
	main: '#ffffff',
	light: '#ffffff90',
	dark: '#626363',
	contrastText: '#19255b',
};

export const themeChart = [
	'#19255b',
	'#75b7e0',
	'#a4a7a5',
	'#757c9d',
	'#acd4ec',
	'#c8cac9',
	'#d1d3de',
	'#e3f1f9',
	'#ededed',
];

export const ThemePalette = createTheme({
	palette: {
		primary: primaryPalette,
		secondary: secondaryPalette,
		white: whitePalette,
	},
});

export const ThemeBasic = (): Theme =>
	extendTheme(
		createTheme(ThemePalette, {
			typography: {
				htmlFontSize: 14,
				fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
				fontSize: 14,
				h1: {
					fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
					fontWeight: 600,
					fontSize: 30,
					lineHeight: 'normal',
				},
				h2: {
					fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 25,
					lineHeight: 'normal',
				},
				h3: {
					fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 22,
					lineHeight: 'normal',
				},
				h4: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 20,
					lineHeight: 'normal',
				},
				h5: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 18,
					lineHeight: 'normal',
				},
				h6: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 16,
					lineHeight: 'normal',
				},
				subtitle1: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 14,
					lineHeight: 'normal',
				},
				subtitle2: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 700,
					fontSize: 12,
					lineHeight: 'normal',
				},
				largeBody: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 'normal',
				},
				body1: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					fontSize: 14,
					lineHeight: 'normal',
				},
				body2: {
					fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					fontSize: 12,
					lineHeight: 'normal',
				},
				button: {
					textTransform: 'none',
				},
			},
			components: {
				MuiCssBaseline: {
					styleOverrides: () => ({}),
				},
				MuiButton: {
					variants: [
						{
							props: { variant: 'AcceptDialog' },
							style: {
								display: 'flex',
								padding: ' 11px 16px',
								alignItems: 'center',
								gap: '12px',
								borderRadius: '8px',
								width: '100%',
								height: 'auto',
								backgroundColor: `${primaryPalette.main}`,
								color: `${White}`,
								transition: '0.7s',

								'&:hover': {
									color: `${White}`,
									background: `${secondaryPalette.main}`,
									transition: '0.7s',
									border: `1px solid ${White}`,
									fontWeight: '700',
								},
								'&:disabled': {
									transition: '0.7s',
									color: `${White}`,
									background: `${Gray75}`,
								},
							},
						},
						{
							props: { variant: 'OtherActionDialog' },
							style: {
								display: 'flex',
								padding: '11px 16px',
								alignItems: 'center',
								gap: '12px',
								borderRadius: '8px',
								width: '100%',
								height: 'auto',
								border: `1px solid ${primaryPalette.main}`,
								margin: '8px 0 16px 0 !important',
								color: `${primaryPalette.main}`,
								'&:hover': {
									color: `${White}`,
									background: `${secondaryPalette.main}`,
									transition: '0.7s',
									border: `1px solid ${White}`,
									fontWeight: '700',
								},
							},
						},
						{
							props: { variant: 'Create' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								transition: '0.7s',
								marginRight: '3px',
								border: `solid 1px ${secondaryPalette.main}`,
								backgroundColor: `${secondaryPalette.main}`,
								color: `${White}`,
								svg: {
									width: '1rem',
									height: '1rem',
								},

								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${secondaryPalette.main}`,
									transition: '0.7s',
									border: `solid 1px ${secondaryPalette.main}`,
									background: `${White}`,
									svg: {
										fill: `${secondaryPalette.main}`,
									},
								},
								// disabled
								'&.Mui-disabled': {
									opacity: 0.7,
									cursor: 'not-allowed',
									color: whitePalette.light,
								},
							},
						},
						{
							props: { variant: 'Edit' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								transition: '0.7s',
								backgroundColor: `${primaryPalette.main}`,
								color: `${White}`,
								border: `solid 1px ${primaryPalette.main}`,
								marginRight: '3px',

								svg: {
									width: '1rem',
									height: '1rem',
								},

								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${primaryPalette.main}`,
									transition: '0.7s',
									border: `solid 1px ${primaryPalette.main}`,
									background: `${White}`,
									svg: {
										fill: `${primaryPalette.main}`,
									},
								},
							},
						},
						{
							props: { variant: 'Delete' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								transition: '0.7s',
								backgroundColor: `${ThemePalette.palette.error.main}`,
								color: `${White}`,
								border: `solid 1px ${ThemePalette.palette.error.main}`,
								marginRight: '3px',

								svg: {
									width: '1rem',
									height: '1rem',
								},

								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${ThemePalette.palette.error.main}`,
									border: `solid 1px ${ThemePalette.palette.error.main}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${ThemePalette.palette.error.main}`,
									},
								},
							},
						},
						{
							props: { variant: 'View' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								backgroundColor: `${BlueLight}`,
								color: `${White}`,
								marginRight: '3px',
								border: `solid 1px ${BlueLight}`,

								transition: '0.7s',
								svg: {
									width: '1rem',
									height: '1rem',
								},
								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${BlueLight}`,
									border: `solid 1px ${BlueLight}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${BlueLight}`,
									},
								},
							},
						},
						{
							props: { variant: 'Activate' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								backgroundColor: `${GreenLight}`,
								color: `${White}`,
								transition: '0.7s',
								marginRight: '3px',
								border: `solid 1px ${GreenLight}`,
								svg: {
									width: '1rem',
									height: '1rem',
								},
								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${GreenLight}`,
									border: `solid 1px ${GreenLight}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${GreenLight}`,
									},
								},
							},
						},
						{
							props: { variant: 'Deactivate' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								backgroundColor: `${RedLight}`,
								color: `${White}`,
								transition: '0.7s',
								marginRight: '3px',
								border: `solid 1px ${RedLight}`,
								svg: {
									width: '1rem',
									height: '1rem',
								},
								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${RedLight}`,
									border: `solid 1px ${RedLight}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${RedLight}`,
									},
								},
							},
						},
						{
							props: { variant: 'Back' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								padding: '5px 10px',
								backgroundColor: `${DarkBlue}`,
								color: `${White}`,
								transition: '0.7s',
								marginRight: '3px',
								border: `solid 1px ${DarkBlue}`,
								svg: {
									width: '1rem',
									height: '1rem',
								},
								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${DarkBlue}`,
									border: `solid 1px ${DarkBlue}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${DarkBlue}`,
									},
								},
							},
						},
						{
							props: { variant: 'Confirm' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								transition: '0.7s',
								backgroundColor: `${GreenLight}`,
								color: `${White}`,
								border: `solid 1px ${GreenLight}`,
								marginRight: '3px',

								svg: {
									width: '1rem',
									height: '1rem',
								},

								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${GreenLight}`,
									border: `solid 1px ${GreenLight}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${GreenLight}`,
									},
								},
							},
						},
						{
							props: { variant: 'Cancel' },
							style: {
								'@media (max-width: 1500px)': {
									'.MuiTypography-root': {
										display: 'none',
									},
								},
								minWidth: '20px',
								padding: '5px 10px',
								transition: '0.7s',
								backgroundColor: `${ThemePalette.palette.error.main}`,
								color: `${White}`,
								border: `solid 1px ${ThemePalette.palette.error.main}`,
								marginRight: '3px',

								svg: {
									width: '1rem',
									height: '1rem',
								},

								'.MuiContainer-root': {
									padding: '0px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'&:hover': {
									color: `${ThemePalette.palette.error.main}`,
									border: `solid 1px ${ThemePalette.palette.error.main}`,
									transition: '0.7s',
									background: `${White}`,
									svg: {
										fill: `${ThemePalette.palette.error.main}`,
									},
								},
								// disabled
							},
						},
					],
				},
				MuiTypography: {
					defaultProps: {
						variantMapping: {
							ExplicationTextBold: 'p',
							ExplicationText: 'p',
						},
					},
					variants: [
						{
							props: { variant: 'h1', className: 'headerH1Orange' },
							style: {
								color: `${primaryPalette.main}`,
								transition: '0.7s',
								textAlign: 'center',
								margin: '2rem 0 1rem 0',
							},
						},
						{
							props: { variant: 'h3', className: 'titlePageAdmin' },
							style: {
								color: `${secondaryPalette.main}`,
								fontSize: '2rem',
								transition: '0.7s',
								textAlign: 'center',
							},
						},
						{
							props: { variant: 'ExplicationTextBold' },
							style: {
								fontSize: 'clamp(11px,3vw, 14px)',
								fontWeight: 'bold',
								color: ThemePalette.palette.secondary.main,
							},
						},
					],
				},
			},
		})
	);
export default ThemeBasic;
